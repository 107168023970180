import React, { useContext, useState, useEffect, useReducer, useRef, } from "react";
import {
  Card,
  Col,
  Radio,
  Row,
  Form,
  Select,
  DatePicker,
  Input,
  message,
  Button,
  Drawer,
  Skeleton,
  Tooltip,
  Modal,
  Collapse,
  Checkbox,
  Divider,
  Statistic,
} from "antd";
import Slider from "react-slick";
import {
  InfoCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  HomeTwoTone,
  UserOutlined,
  IdcardOutlined,
  SolutionOutlined,
  LikeOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import PaymentComponent from "../../helpers/CCavanuePayCheckout";
import { GlobalStatesContext } from "../../common/providers";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useFlightContext } from "../../common/providers/Flights/FlightContext";
import { getPassengerData } from "../../helpers/PassegerData";
import FlightDetailsCard from "./Flight-Details-Card";
import FareSummaryCard from "./FareSummaryCard";
import BookPayCard from "./Book-Pay-Card";
import ApiClient from "../../helpers/ApiClient";
import { getFlightGrandTotalPrice } from "../FlightsCheckout/flightPriceHelper";
import moment from "moment";
import mail from "../../assets/images/mail.png";
import FlightGrandTotalCard from "./FlightGrandTotalCard";
import CountryList from "../../common/CountryList";
import ssrbag from "../../assets/images/baggs.jpg"
import admeal from "../../assets/images/Flighticons/icons8-food-50.png";
import adbag from "../../assets/images/Flighticons/bagg1.png";
import fliy from "../../assets/images/Icons/fli8-20.png";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import ssrmeal from "../../assets/images/ssr-meal.jpg";
import { PaxAgeValidator } from "../../helpers/CustomValidators";

import {
  ArrowLeftOutlined,
  SafetyCertificateOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import queryString from "query-string";


import "./FlightsCheckout.scss";
import { Header } from "antd/lib/layout/layout";

const { Option } = Select;
const { Countdown } = Statistic;
let dateformat = "DD-MM-YYYY";
let oriDateFormat = "YYYY-MM-DD";

const validateMessages = {
  required: "",
};
const abbrevations = [
  "MR",
  "Mr",
  "mr",
  "mR",
  "MRS",
  "Mrs",
  "mrs",
  "mrS",
  "mRs",
  "MS",
  "ms",
  "Ms",
  "mS",
  "Mstr",
  "mstr",
  "Miss",
  "miss",
  ".",
];
const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";


const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}
const FlightCheckout = ({ location }) => {
  const {
    isLogin: { flag },
    isLogin: { agent },
    user,
  } = useAuthContext();


  const { updateFlightAirPrice, updateAirBookState, getsessiontimeout, state: { sessiontimeout, status }, } = useFlightContext();
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [insuranceRequired, setInsuranceRequired] = useState(-1);
  const [openCCavanueCheckout, setOpenCCavanueCheckout] = useState(false);
  const [contact_form] = Form.useForm();
  const [passenger_form] = Form.useForm();
  const [gst_form] = Form.useForm();
  const [gstReq, setgstReq] = useState(false);
  const [airPriceResp, setAirPriceResp] = useState({ flightDetails: [] });
  const [totalPaxArr, setTotalPaxArr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGstRequired, setIsGstRequired] = useState(false);
  const [valemail, setvalemail] = useState();
  const [passengerRequiredFields, setpassengerRequiredFields] = useState({});
  const [additional_services, setAdditional_services] = useState([]);
  const [SelectedServices, setSelcetedServices] = useState({
    0: { keys: [], services: [] },
    2: { keys: [], services: [] },
    1: { keys: [], services: [] },
    3: { keys: [], services: [] },
    4: { keys: [], services: [] },
    5: { keys: [], services: [] },
    6: { keys: [], services: [] },
    7: { keys: [], services: [] },
    8: { keys: [], services: [] },
  });
  const [showameal, setshowMeal] = useState(false);
  const [showbagg, setshowBagg] = useState(false);
  const [showalldetails, setshowalldetails] = useState(false);
  const [showallssrdetails, setshowallssrdetails] = useState(false);
  const [bagReq, setbagReq] = useState(false);
  const [mealVisible, setmealVisible] = useState(false);
  const [baggagePlanArr, setbaggagePlanArr] = useState([]);
  const [baggageVisible, setbaggageVisible] = useState(false);
  const [travellersListResp, setTravellersListResp] = useState({
    Travellers: [],
  });

  
  const {
    state: {
      otherData: { promoData, ConvFee, selectedInsuranceData, redeemAmount },
    },
    UpdateMealData,
    UpdateBaggageData,
    setSelectedInsuranceData,
    RemovePromoConvFee,

  } = useContext(GlobalStatesContext);

  const {
    updateAirBookRespState,
    state: {
      airBookStateObj,
      flightAirPriceResp,
      flightSearchObj,
      selectedFlight,
    },
  } = useFlightContext();
  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);
  const [mealPlanPaxArr, setmealPlanPaxArr] = useState([]);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const history = useHistory();
  const [pgData, setPgData] = useState({});
  const [openRazorpayCheckout, setOpenrazorpayCheckout] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [passengerData, setpassengerData] = useState(null);
  const [activeKey, setActiveKey] = useState(['1']);
  const [activeMeal, setactiveMeal] = useState(['0', '0']);
  const [activebagg, setactivebagg] = useState(['0', '0']);
  const [activeMealSegment, setactiveMealSegment] = useState(null);
  const [activebaggSegment, setactivebaggSegment] = useState(null);
  const onChange = (key) => {
    setActiveKey(key);
  };
  const [mealData, setMealData] = useState([]);
  const [baggData, setBaggData] = useState([]);

  useEffect(() => { getsessiontimeout() }, [])
  const userReg = (name, email, mobile) => {
    let regObj = {
      Name: name,
      Email: email,
      DialingCode: "+91",
      Mobile: mobile,
      Password: "",
      Role: 4,
      DeviceToken: "string",
      DeviceType: "Web",
      FirBaseToken: "string",
    };

    ApiClient.post("admin/user/register", regObj)
      .then(() => { })
      .catch();
  };
  const ccavanueCheckoutData = (resp) => {
    setPgData(resp);
    setOpenCCavanueCheckout(true);
  };
  const getFlightIds = () => {
    if (flightSearchObj?.airTravelType === "oneWay") {
      return [selectedFlight[0]?.flightId];
    } else {
      return selectedFlight?.map((flightID) => flightID?.flightId);
    }
  };
  const CarouselPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-table", background: "#12499d" }}
        onClick={onClick}
      />
    );
  };
  const CarouselNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-table", background: "#12499d" }}
        onClick={onClick}
      />
    );
  };
 
  const settingsMeal = {
   
    className: "center",
    
    slidesToShow: airPriceResp?.specialServiceRequest?.[0]?.MEAL?.length > 5 ? 5 : airPriceResp?.specialServiceRequest?.[0]?.MEAL?.length,
    
    autoplaySpeed: 2000,
    rows: 1,
    slidesPerRow: 1,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,

  };
  const settings = {
   
    className: "center",
    
    slidesToShow: airPriceResp?.specialServiceRequest?.[0]?.BAGGAGE?.length > 5 ? 5 : airPriceResp?.specialServiceRequest?.[0]?.BAGGAGE?.length,
   
    autoplaySpeed: 2000,
    rows: 1,
    slidesPerRow: 1,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };
  const MobileSlidersettings = {
    infinite: true,
    className: "center",
    lazyLoad: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    rows: 1,
    slidesPerRow: 1,
    pauseOnHover: true,
    arrows: false,
  };


  const fetchAirBook = (pgType = null, airBookStateObjdata) => {
    
    if (!user) {
      userReg(
        airBookStateObjdata.passengers[0].firstName,
        airBookStateObjdata.passengers[0].email,
        airBookStateObjdata.passengers[0].mobile
      );
    }
    const allAmount = getFlightGrandTotalPrice(
      flightAirPriceResp,
      ConvFee,
      promoData,
      flightSearchObj,
      selectedInsuranceData,
      redeemAmount,
      mealData,
      baggData
    );

    airBookStateObjdata.promoCode = promoData.Code ?? "";
    airBookStateObjdata.convienenceId = ConvFee.id ?? 0;
    airBookStateObjdata.pgType = pgType;
    
    airBookStateObjdata.totalPrice = Number(allAmount.grandTotal);
    airBookStateObjdata.isCouponReedem = redeemAmount.status ?? false;
    setLoadingSpin(true);
    setIsLoading(true);
    ApiClient.post("flights/airBlock", airBookStateObjdata)
      .then((resp) => {

        return resp;
      })
      .then((resp) => {

        setIsLoading(false);
        setLoadingSpin(false);
        if (resp.statusCode === 200) {

          if (resp.data.pgType == 4) {
            ccavanueCheckoutData(resp.data.payment_link);
          } else {
            window.location.href = resp.data.payment_link;
          }
        }
        else {
          if (resp?.message) message.error(resp.message, 3);
          else if (resp?.data[0]?.errorDetail)
            message.error(resp?.data[0]?.errorDetail, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatchPgDetails({ type: PGDISPLAY, payload: false });
        setLoadingSpin(false);
        setIsLoading(false);
      });
  };

  const RazorPayCheckoutData = (resp) => {
    setPgData(resp);
    setOpenrazorpayCheckout(true);
  };

  const processPayGateway = () => {
    const h = redirectToPreview();
    dispatchPgDetails({ type: PGDISPLAY, payload: true });
  };

  const blockApiReq = (pgType) => {
    if (pgType) {
      redirectToPreviews(pgType);

    }
  };


  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyserviceOtp", {
      Mobile: airBookStateObj.passengers[0].mobile,
      DialingCode: airBookStateObj.passengers[0].areaCode,
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          fetchAirBook();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = () => {
    ApiClient.post("admin/sendserviceOtp", {
      Mobile: airBookStateObj.passengers[0].mobile,
      DialingCode: airBookStateObj.passengers[0].areaCode,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };
  const ValidateBookingLimit = () => {
    const { grandTotal } = getFlightGrandTotalPrice(
      flightAirPriceResp,
      ConvFee,
      promoData,
      flightSearchObj,
      selectedInsuranceData,
      redeemAmount
    );
    ApiClient.post("admin/validatebookinglimit", {
      serviceType: 1,
      roleType: user?.Role?.RoleId ?? 4,
      bookingAmount: grandTotal ? grandTotal : 0,
    })
      .then((res) => {
        if (res?.status === 200 && res?.isValid) {
          handleSendOTP();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };
  /* */

  const getAirPrice = () => {
    if (flightSearchObj) {
      let selectedFlightArray = [];
      if (flightSearchObj.airTravelType === "multidestination") {
        if (
          flightSearchObj.resultsType === "COMBINED" &&
          flightSearchObj.airTravelType === "multidestination"
        ) {
          selectedFlightArray.push({
            fareId: selectedFlight[0].fareFamilies.fareFamilies[0]?.fareId,
            flightId: selectedFlight[0].flightId,
            coupanType:
              selectedFlight[0].fareFamilies.fareFamilies[0]?.coupanType,
            fareType: selectedFlight[0].fareFamilies.fareFamilies[0]?.fareType,
            subCabinClass:
              selectedFlight[0].fareFamilies.fareFamilies[0]?.segmentInfos[0]
                ?.cabinSubClass,
          });
        } else {
          selectedFlight.map((item, index) => {
            return selectedFlightArray.push({
              fareId: item.fareFamilies.fareFamilies[0]?.fareId,
              flightId: item.flightId,
              coupanType: item.fareFamilies.fareFamilies[0]?.coupanType,
              fareType: item.fareFamilies.fareFamilies[0]?.fareType,
              subCabinClass:
                item.fareFamilies.fareFamilies[0]?.segmentInfos[0]
                  ?.cabinSubClass,
            });
          });
        }
      } else {
        if (
          flightSearchObj.resultsType === "COMBINED" &&
          flightSearchObj.airTravelType === "roundTrip"
        ) {
          selectedFlightArray.push({
            fareId: selectedFlight[0].fareFamilies.fareFamilies[0]?.fareId,
            flightId: selectedFlight[0].flightId,
            coupanType:
              selectedFlight[0].fareFamilies.fareFamilies[0]?.coupanType,
            fareType: selectedFlight[0].fareFamilies.fareFamilies[0]?.fareType,
            subCabinClass:
              selectedFlight[0].fareFamilies.fareFamilies[0]?.segmentInfos[0]
                ?.cabinSubClass,
          });
        } else {
          selectedFlight.map((item, index) => {
            return selectedFlightArray.push({
              fareId: item.fareFamilies.fareFamilies[0]?.fareId,
              flightId: item.flightId,
              coupanType: item.fareFamilies.fareFamilies[0]?.coupanType,
              fareType: item.fareFamilies.fareFamilies[0]?.fareType,
              subCabinClass:
                item.fareFamilies.fareFamilies[0]?.segmentInfos[0]
                  ?.cabinSubClass,
            });
          });
        }
      }
      setIsLoading(true);

      let airPriceReqObj = {
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
        traceId: flightSearchObj.traceId,
        flightIds: getFlightIds(),
        selectedFlights: selectedFlightArray,
        airTravelType: flightSearchObj.airTravelType,
        mappingType: flightSearchObj.resultsType,
        itineraryViewType: "1",
        gstDetails: {
          gstAddressLine1: "",
          gstAddressLine2: "",
          gstCity: "",
          gstState: "",
          gstpinCode: "",
          gstEmailId: "",
          gstNumber: "",
          gstPhoneNo: "",
          gstCompanyName: "",
        },
      };
      ApiClient.post("flights/airPrice", airPriceReqObj)
        .then((resp) => {
          return resp;
        })
        .then((resp) => {

          setUpFromApiResponse(resp);
        })
        .catch((err) => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  };
  const setUpFromApiResponse = (resp) => {
    if (resp.statusCode === 200) {
      setAirPriceResp(resp.data);
      paxInfoSections(resp.data);
      ssr_Services(resp.data);
      handleGstRequired(resp.data);
      updateFlightAirPrice(resp.data);
      GetMealData(resp?.data?.specialServiceRequest);
      var temp = getHeader(resp?.data?.specialServiceRequest?.[0]?.MEAL)[0]
      setactiveMealSegment(temp);
      var temp1 = getHeader(resp?.data?.specialServiceRequest?.[0]?.BAGGAGE)[0]
      setactivebaggSegment(temp1);
    } else if (resp.statusCode === 400) {
      if (resp?.errors) {
        let errorsArr = resp.errors.filter(
          (item) => item.errorCode === "Status204NoContent"
        );
        if (errorsArr?.length > 0) {
          message.error(errorsArr[0].errorDetail, 4);
        }
      }
    }
    setIsLoading(false);
  };
  const onChangeModal = () => {
    if (baggageVisible) {
      setbaggageVisible(false)
      setmealVisible(true)
    }
    else if (setmealVisible) {
      setbaggageVisible(true)
      setmealVisible(false)
    }
  }
  const GetMealData = (data) => {
   
    let mealDataTempArr = [];
    let baggDatatempArr = [];
    if (data != null) {
      for (let i = 0; i < data?.length; i++) {
        let mealFlightObj = [];
        let bagFlightObj = [];
        var connFlights = getHeader(data[i]?.MEAL)
        var connBagFlights = getHeader(data[i]?.BAGGAGE)
        for (let j = 0; j < connFlights?.length; j++) {
          let mealConnObj = [];
          for (let k = 0; k < flightSearchObj?.adultCount + flightSearchObj?.childCount + flightSearchObj?.infantCount; k++) {
            let paxObj =
            {
              "amount": 0,
              "cityPair": connFlights[j],
              "paxType": "",
              "mealCode": "",
              "mealDesc": ""

            }
            mealConnObj.push(paxObj)
          }
          mealFlightObj.push(mealConnObj)
        }
        mealDataTempArr.push(mealFlightObj);
        for (let j = 0; j < connBagFlights?.length; j++) {
          let baggConnObj = [];
          for (let k = 0; k < flightSearchObj?.adultCount + flightSearchObj?.childCount + flightSearchObj?.infantCount; k++) {
            let paxObj =
            {
              "amount": 0,
              "cityPair": connBagFlights[j],
              "paxType": "",
              "baggCode": "",
              "baggWeight": ""

            }
            baggConnObj.push(paxObj)
          }
          bagFlightObj.push(baggConnObj)
        }
        baggDatatempArr.push(bagFlightObj);
      }
      setMealData(mealDataTempArr);
      setBaggData(baggDatatempArr)
      
    }
  }

  const loadpassengerData = () => {
    if (user && user?.UserID) {
      getPassengerData(user.UserID).then((data) => {
        if (data.status) {
          contact_form.setFieldsValue({
            mobile: data.Mobile,
            address: data?.Address1 ? data?.Address1 : "",
            city: data?.city ? data?.city : "",
            state: data?.state ? data?.state : "",
          });
        }
      });
    }
  };

  useEffect(() => {
    RemovePromoConvFee();
    getAirPrice();
    loadpassengerData();
    ssr_Services();
  }, []);

  useEffect(() => {
    fetchTravellerDetails();
  }, [user]);

  const handleGstRequired = (data) => {
    let count = 0;
    if (data?.flightDetails?.length > 0) {
      data.flightDetails.forEach((details) => {
        if (details?.fareFamilies?.length > 0) {
          if (details.fareFamilies[0].isGstMandatory) {
            count++;
          }
        }
      });
    }
    if (count > 0) {
      setIsGstRequired(true);
    }
  };

  const ssr_Services = (airPriceResp) => {

    if (airPriceResp?.specialServiceRequest?.length > 0) {

      setshowallssrdetails(true);
      airPriceResp?.specialServiceRequest?.forEach((item) => {

        if (item?.BAGGAGE.length > 0 && item.MEAL.length > 0) {
          setshowMeal(true);
          setshowBagg(true);
        }
        else if (item?.BAGGAGE.length > 0) {
          setshowBagg(true);
        }
        else if (item.MEAL.length > 0) {
          setshowMeal(true);
        }
        else {
          setshowMeal(false);
          setshowBagg(false);
        }
      });
    }

  };

  const paxInfoSections = (airPriceRespObj) => {


    let paxInfoArr = [];
    let allPaxInfoArr = [];
    let mealPlanArr = [];
    let baggagePlanArr = [];
    for (let i = 0; i < flightSearchObj.adultCount; i++) {
      paxInfoArr.push({
        type: "ADT",
        pax: i + 1,
      });
    }

    for (let i = 0; i < flightSearchObj.childCount; i++) {
      paxInfoArr.push({
        type: "CHD",
        pax: i + 1,
      });
    }

    for (let i = 0; i < flightSearchObj.infantCount; i++) {
      paxInfoArr.push({
        type: "INF",
        pax: i + 1,
      });
    }
    if (airPriceRespObj.passengerRequiredFields.length > 0)
      setpassengerRequiredFields(airPriceRespObj.passengerRequiredFields[0]);

    paxInfoArr.map((obj, index) => {
      let mealPlanObj = {
        paxType: obj.type,
        index: index,
        mealplancode: "NoMeal",
        amount: 0,
        mealDesc: "",

      }
      let baggagePlanObj = {
        paxType: obj.type,
        index: index,
        baggagePlancode: "NoBagge",
        amount: 0,
        baggWeight: "",
      }
      baggagePlanArr.push(baggagePlanObj);
      mealPlanArr.push(mealPlanObj);
      setbaggagePlanArr(baggagePlanArr);
      setmealPlanPaxArr(mealPlanArr);
      let singlePaxObj = {
        title: obj.type === "ADT" ? "MR" : "CHD" ? "MSTR" : "MSTR",
        firstName: "",
        lastName: "",
        paxType: "",
        id: index,
        pax: obj.pax,
      };

      airPriceRespObj.passengerRequiredFields
        .filter((requiredFiledsObj) => requiredFiledsObj.paxType === obj.type)
        .map((paxReqFieldsObj) => {
          Object.keys(paxReqFieldsObj).map((paxReqKeys) => {
            if (
              paxReqFieldsObj[paxReqKeys] === true &&
              paxReqKeys !== "address"
            ) {
              singlePaxObj[paxReqKeys] = "";
              if (
                paxReqKeys === "passengerNationality" ||
                paxReqKeys === "passportIssuedCountry"
              ) {
                singlePaxObj[paxReqKeys] = "";
              }
            }
          });
          singlePaxObj["paxType"] = paxReqFieldsObj.paxType;
        });
      {
      }
      allPaxInfoArr.push(singlePaxObj);
    });

    setTotalPaxArr(allPaxInfoArr);
  };
  const mealPaxObj = (obj, k) => {
    let arr = [];
    let m = [];
    arr = mealData;
    
    for (let i = 0; i < mealData?.length; i++) {
      m.push([]);
      for (let j = 0; j < mealData[i]?.length; j++) {
        m[i].push(arr[i]?.[j]?.filter(x => x?.paxType === (obj.paxType + k)))
      }
    }
   

    return m;
  }

  const baggPaxObj = (obj, k) => {
    let arr1 = [];
    let b = [];
    arr1 = baggData;
   
    for (let i = 0; i < baggData?.length; i++) {
      b.push([]);
      for (let j = 0; j < baggData[i]?.length; j++) {
        b[i].push(arr1[i].[j]?.filter(x => x?.paxType === (obj?.paxType + k)))
      }
    }
    return b;
  }

  const redirectToPreview = () => {
    dispatchPgDetails({ type: PGDISPLAY, payload: true });
  };
  const redirectToPreviews = (type) => {
   
    contact_form
      .validateFields()
      .then((contact_data) => {
        passenger_form
          .validateFields()
          .then((passegers_data) => {
            gst_form
              .validateFields()
              .then((gstDetails) => {
                onFlicghtCheckoutValidated(
                  contact_data,
                  passegers_data,
                  gstDetails,
                  type
                );
              })
              .catch((e) => {
                dispatchPgDetails({ type: PGDISPLAY, payload: false });
              });
          })
          .catch((e) => {
            dispatchPgDetails({ type: PGDISPLAY, payload: false });
            passenger_form.scrollToField(e.errorFields[0].name);
          });
      })
      .catch((e) => {
        dispatchPgDetails({ type: PGDISPLAY, payload: false });
        if (!e.errorFields) {
          return;
        }
        contact_form.scrollToField(e.errorFields[0].name);
      });
  };

  const onFlicghtCheckoutValidated = (
    contactDetails,
    passegersData,
    gstDetails,
    pgtype
  ) => {
    try {
      UpdateMealData(mealData);
      UpdateBaggageData(baggData);
      sessionStorage.setItem(
        "selected_services",
        JSON.stringify(SelectedServices)
      );
      
      let passegers_data = passegersData.passengers;


      passegers_data.map((passengersObj, i) => {
        passengersObj["mealPref"] = mealPaxObj(passengersObj, i);
        passengersObj["baggagePref"] = baggPaxObj(passengersObj, i);
        passengersObj["email"] = valemail;
        passengersObj["mobile"] = contactDetails.mobile;
        passengersObj["areaCode"] = contactDetails.areaCode;
        if (contactDetails.countryName) {
          let countryCode = CountryList.filter(
            (item) => item.name === contactDetails.countryName
          )[0].code;

          passengersObj["address_CountryCode"] = countryCode;
        } else {
          passengersObj["address_CountryCode"] = "";
        }
        if (passengersObj.dob) {
          passengersObj["dob"] = passengersObj?.dob;
        }

        if (passengersObj.passportDOE) {
          passengersObj["passportDOE"] = passengersObj.passportDOE;
        }
        if (passengersObj.passportDOI) {
          passengersObj["passportDOI"] = passengersObj.passportDOI

        }

        passengersObj["address"] = contactDetails.address;
        passengersObj["city"] = contactDetails.city;
        passengersObj["countryName"] = contactDetails.countryName;
        if (contactDetails.state) {
          passengersObj["state"] = contactDetails.state;
        }

        if (contactDetails.postalCode) {
          passengersObj["postalCode"] = contactDetails.postalCode;
        }

        passengersObj["additionalServicesIds"] = SelectedServices[i].services;
        passengersObj["gender"] =
          passengersObj.title === "MR" || passengersObj.title === "MSTR"
            ? "m"
            : "f";
        delete passengersObj["countryCode"];
        delete passengersObj["id"];
        return passengersObj;
      });
      let airBookReqObj = {
        traceId: flightSearchObj.traceId,
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
        passengers: passegers_data,
        additional_services: additional_services,
        gstDetails: {
          gstAddressLine1: gstDetails.gstAddressLine1
            ? gstDetails.gstAddressLine1
            : "",
          gstAddressLine2: "",
          gstCity: "",
          gstState: "",
          gstpinCode: "",
          gstEmailId: gstDetails.gstEmailId ? gstDetails.gstEmailId : "",
          gstNumber: gstDetails.gstNumber ? gstDetails.gstNumber : "",
          gstPhoneNo: gstDetails.gstPhoneNo ? gstDetails.gstPhoneNo : "",
          gstCompanyName: gstDetails.gstCompanyName
            ? gstDetails.gstCompanyName
            : "",
        },
        creditCardInfo: "",
        insuranceRequired: passegersData?.insuranceRequired === 1 ? 1 : 0,
      };
    
      fetchAirBook(pgtype, airBookReqObj);

    } catch (error) {
    }
  };
  const disabledFutureDate = (currentDate) => {
    return currentDate > moment().startOf("day");
  };
  const fetchTravellerDetails = () => {
    if (!user) return;
    ApiClient.get("admin/user/travellers/" + user.UserID)
      .then((resp) => resp)
      .then((resp) => {
        if (resp.status === 200) {
          setTravellersListResp(resp.data);
        } else {
        }
      })
      .catch((err) => err);
  };
  const getDetails = () => {
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regEmail.test(valemail)) {
      return "Invalid Email";
    }

    setshowalldetails(true);
  };
  const handleTravelerDetails = (val, paxIndex) => {
    let selectedTraveler = travellersListResp.Travellers.find(
      (traveler) => traveler.TravellerID === val
    );

    let { passengers } = passenger_form.getFieldValue();
    let newObj = {
      ...passengers[paxIndex],
      title: selectedTraveler.Gender === 0 ? "MR" : "Ms",
      firstName: selectedTraveler.FirstName,
      lastName: selectedTraveler.LastName,
    };
    Object.keys(newObj).map((key) => {
      if (key === "dob") {
        newObj[key] = selectedTraveler.DOB ? moment(selectedTraveler.DOB).format(oriDateFormat) : "";
      }
      if (key === "passengerNationality") {
        newObj[key] = selectedTraveler?.PassengerNationality ?? selectedTraveler?.passportIssuedCountry;
      }
      if (key === "passportNumber") {
        newObj[key] = selectedTraveler.PassportNumber ?? "";
      }
      if (key === "passportDOI") {
        newObj[key] = selectedTraveler.PassportDOE
          ? moment(selectedTraveler.passportDOI)
          : "";
      }
      if (key === "passportDOE") {
        newObj[key] = selectedTraveler.PassportDOE
          ? moment(selectedTraveler.PassportDOE)
          : "";
      }
    });
    Object.assign(passengers[paxIndex], newObj);

    passenger_form.setFieldsValue({ passengers });
  };
  const GetIndex = (cityPair) => {

    let index = 0;
    for (let i = 0; i < mealData?.length; i++) {

      for (let j = 0; j < mealData[i]?.length; j++) {

        for (let k = 0; k < mealData?.[i]?.[j]?.length; k++) {
          if (mealData?.[i]?.[j]?.[k]?.cityPair === cityPair) {
            index = j;
            break;
          }
        }

      }
    }
    return index;
  }
  const GetIndx = (cityPair) => {
    let indx = 0;
    for (let i = 0; i < baggData?.length; i++) {

      for (let j = 0; j < baggData[i]?.length; j++) {

        for (let k = 0; k < baggData?.[i]?.[j]?.length; k++) {
          if (baggData?.[i]?.[j]?.[k]?.cityPair === cityPair) {
            indx = j;
            break;
          }
        }

      }
    }
    return indx;
  }
  const onCheckChange = (e) => {
    setgstReq(e.target.checked);
  };
  const [mcode, setMcode] = useState([]);
  const [madd, setMAdd] = useState(false);
  const [badd, setBAdd] = useState(false);
  const [bcode, setBcode] = useState([]);
  const [mealSelection, setMealSelection] = useState({});
  const [baggSelection, setBaggSelection] = useState({});
  const getmealcode = (selectedMeal, index, paxType, type) => {

    for (let i = 0; i < mealData?.length; i++) {
      for (let j = 0; j < mealData[i]?.length; j++) {

        if (mealData[i]?.[j]?.[index]?.cityPair === selectedMeal?.cityPair) {
          let temp = [];
          temp = mealData;
          if (type === "add") {
            temp[i].[j].[index].paxType = paxType;
            temp[i].[j].[index].amount = selectedMeal?.amount;
            temp[i].[j].[index].mealCode = selectedMeal?.code;
            temp[i].[j].[index].mealDesc = selectedMeal?.airlineDesc;
          } else if (type === "remove") {

            temp[i][j][index].paxType = null;
            temp[i][j][index].amount = null;
            temp[i][j][index].mealCode = null;
            temp[i][j][index].mealDesc = null;
          }
          setMealData(temp);
        }
      }

    }


    let temp = [];
    temp = mealPlanPaxArr;
    temp[index].mealplancode = selectedMeal?.code;
    temp[index].amount = selectedMeal?.amount
    setmealPlanPaxArr(temp)
    setMcode(temp[index].mealplancode)
    setMAdd(true)
    setMealSelection((prevSelection) => {
      return {
        ...prevSelection,
        [index]: selectedMeal.code,
      };
    });
  }
  const getbaggcode = (selectedBagg, index, paxType, type) => {

    for (let i = 0; i < baggData?.length; i++) {
      for (let j = 0; j < baggData[i]?.length; j++) {

        if (baggData[i]?.[j]?.[index]?.cityPair === selectedBagg?.cityPair) {
          let temp = [];
          temp = baggData;
          if (type === "add") {
            temp[i].[j].[index].paxType = paxType;
            temp[i].[j].[index].amount = selectedBagg?.amount;
            temp[i].[j].[index].baggCode = selectedBagg?.code;
            temp[i].[j].[index].baggWeight = selectedBagg?.weight;
          } else if (type === "remove") {

            temp[i][j][index].paxType = null;
            temp[i][j][index].amount = null;
            temp[i][j][index].baggCode = null;
            temp[i][j][index].baggWeight = null;
          }
          setBaggData(temp);

        }
      }
    }

    let temp = [];
    temp = baggagePlanArr;
    temp[index].bagplancode = selectedBagg?.code;
    temp[index].amount = selectedBagg?.amount
    setbaggagePlanArr(temp)
    setBcode(temp[index].bagplancode)
    setBAdd(true)
    setBaggSelection((prevSelection) => {
      return {
        ...prevSelection,
        [index]: selectedBagg.code,
      };
    });
  }
  const getHeader = (trip) => {
    let arr = [];
    trip?.forEach(e => {
      if (!arr?.includes(e?.cityPair))
        arr.push(e.cityPair)
    })
  
    return arr;
  }
  const handelCountdown = () => {
   
    Modal.warning({
      icon: <ClockCircleOutlined />,
    
      content: (<div><h5>Your Session is Expired</h5><p>Click on "OK" to continue with New Search</p></div>),
      onOk() {

        let queryParams = (JSON.parse(localStorage.getItem('FlightSearchBar')));
        history.push("/flight/results?" + queryParams);
      },
    });
  };

  return (
    <div>

      <div className="checkout-heading">
        <div fluid className="checkout-heading-container">
          <div className="goback">
            <Link
              onClick={() => {
                history.go(-1);
              }}
            >
              <ArrowLeftOutlined />
              <span>Go back and select another flight</span>
            </Link>
          </div>
          <h3>Fill out the form below and book your flight now!</h3>
        </div>
      </div>
      {status != 0 &&
        <div className="checkout-section sectionn-work-for-mob-only ">
          <div style={{ marginLeft: "0%", width: "93%" }} className="countdown3">
            <div className="countdown-main-box">
              <div className="countdown1">
                <span class="fa fa-clock-o" style={{ fontSize: "18px" }}></span><span> Your session will expire in</span>
              </div>
              <div className="countdown2">
                <Countdown
                  className="busCountdown"
                  onFinish={handelCountdown}
                  value={sessiontimeout}
                  format={"mm[ min]  ss[ sec]"}
                />
              </div>
            </div>
          </div>
          <div className="flight-checkout">


            <section className="checkout-body-sec">

              <div className="checkout-container flights-poins-sec">
                <Row gutter={[16, 16]}>
                  <Col md={16} sm={24} xs={24} className="checkout-bottom-part">
                    <FlightDetailsCard
                      selectedFlight={selectedFlight}
                      flightSearchObj={flightSearchObj}
                      travelType={flightSearchObj.airTravelType}
                      validate={airPriceResp?.flightDetails[0]}
                    />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </div>}

      <div className="checkout-section">
        <div className="flight-checkout">
          <section className="checkout-body-sec">
            {status != 0 &&
              <div style={{ marginLeft: "-3%", width: "100%" }} className="countdown3">
                <div className="countdown-main-box">
                  <div className="countdown1">
                    <span class="fa fa-clock-o" style={{ fontSize: "18px" }}></span><span> Your session will expire in</span>
                  </div>
                  <div className="countdown2">
                    <Countdown
                      className="busCountdown"
                      onFinish={handelCountdown}
                      value={sessiontimeout}
                      format={"mm[ min]  ss[ sec]"}
                    />
                  </div>
                </div>
              </div>}
            <div className="checkout-container flights-poins-sec">
              <Row gutter={[16, 16]}>
                <Col className="checkout-bottom-part">
                  <div className="mob-none">
                    <FlightDetailsCard
                      selectedFlight={selectedFlight}
                      flightSearchObj={flightSearchObj}
                      travelType={flightSearchObj?.airTravelType}
                      validate={airPriceResp?.flightDetails[0]}
                    />
                  </div>



                  <div className="user-detail contactwrapper margin-contact">
                    <div className="titlewrapper">
                      {!showalldetails ? (
                        <div className="info-1">
                          <p className="heading">
                            Contact Information
                            <p className="sub-heading">
                              {" "}
                              Your ticket will be sent to this email address
                            </p>
                          </p>
                        </div>
                      ) : null}

                      <Form
                        layout="vertical"
                        className="flight-checkout-contact-form"
                        name="contactForm"
                        form={contact_form}
                        scrollToFirstError={true}
                        initialValues={{ areaCode: agent ? "+91" : "" }}
                      >
                        {!showalldetails ? (
                          <Col md={12} sm={12} xs={24}>
                            <Form.Item
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter your MailId",
                                },
                                { type: "email", message: "Invalid Email" },
                              ]}
                            >
                              <Input
                                placeholder="Enter your Email-Id"
                                size="large"
                                onChange={(e) => {
                                  setvalemail(e.target.value);
                                }}
                                className="email-flight-checkout-select"
                                style={{
                                  borderRadius: "4px",
                                  height: "50px",
                                  marginLeft: "-6px",
                                }}
                                prefix={
                                  <MailOutlined
                                    className="site-form-item-icon"
                                    style={{ color: "rgba(0,0,0,.50)" }}
                                  />
                                }
                                suffix={
                                  <Tooltip title="Your ticket will be sent to this email address">
                                    <InfoCircleOutlined
                                      style={{ color: "rgba(0,0,0,.45)" }}
                                    />
                                  </Tooltip>
                                }
                              />

                            </Form.Item>{" "}
                          </Col>
                        ) : null}

                        {showalldetails ? (
                          <div className="contactDetails-headr">
                            {isLoading ? (
                              <>
                                <p className="heading">Contact Details</p>
                                <Card className="checkout-custom-card">
                                  <Skeleton active />
                                </Card>
                              </>
                            ) : totalPaxArr.length > 0 ? (
                              <>
                                <div className="cntct-info">
                                  <span>
                                    <SolutionOutlined />
                                  </span>{" "}
                                  Contact Details
                                </div>
                                <Card className="cntct-card-info">
                                  <Row gutter={[16, 8]}>
                                    <Col md={12} sm={12} xs={24}>
                                      <Form.Item
                                        label="Phone number"
                                        name="mobile"
                                        className="phno"
                                      >
                                        <Input
                                          addonBefore={
                                            <Form.Item
                                              style={{ width: "20%" }}
                                              name="areaCode"
                                              className="phno"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Phone Number Code Required",
                                                },
                                              ]}
                                              noStyle
                                            >
                                              <Select
                                                showSearch
                                                style={{ width: "100%" }}
                                                filterOption={(input, option) =>
                                                  option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                      input.toLowerCase()
                                                    ) >= 0
                                                }
                                                size="small"
                                              >
                                                {CountryList.map((item) => (
                                                  <Option
                                                    key={item.dial_code}
                                                    value={item.dial_code}
                                                  >
                                                    {item.dial_code}
                                                  </Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                          }
                                          size="large"
                                          className="inputbg"
                                          placeholder="Enter Mobile Number"
                                          prefix={
                                            <PhoneOutlined
                                              className="site-form-item-icon"
                                              style={{
                                                color: "rgba(0,0,0,.50)",
                                                rotate: "90deg",
                                              }}
                                            />
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    {passengerRequiredFields.hasOwnProperty(
                                      "address"
                                    )
                                      ? passengerRequiredFields.address ===
                                      true && (
                                        <Col md={12} sm={12} xs={24}>
                                          <Form.Item
                                            name="address"
                                            label="Address"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Required",
                                              },
                                              {
                                                min: 3,
                                                message: "Invalid Address",
                                              },
                                            ]}
                                          >
                                            <Input
                                              size="large"
                                              placeholder="Current Address"
                                              prefix={
                                                <HomeTwoTone
                                                  className="site-form-item-icon"
                                                  style={{
                                                    color: "rgba(0,0,0,.50)",
                                                  }}
                                                />
                                              }
                                              className="inputbg"
                                            />
                                          </Form.Item>
                                        </Col>
                                      )
                                      : null}
                                    {passengerRequiredFields.hasOwnProperty(
                                      "city"
                                    )
                                      ? passengerRequiredFields.city ===
                                      true && (
                                        <Col md={12} sm={12} xs={24}>
                                          <Form.Item
                                            name="city"
                                            label="City"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Required",
                                              },
                                            ]}
                                          >
                                            <Input className="inputbg" />
                                          </Form.Item>{" "}
                                        </Col>
                                      )
                                      : null}
                                  </Row>
                                </Card>
                              </>
                            ) : null}
                          </div>
                        ) : null}
                      </Form>
                    </div>
                  </div>

                  {showalldetails ? (
                    <div className="user-details paxInfowrapper margin-contact">
                      {isLoading ? (
                        <>
                          <p className="heading">Passenger Details</p>
                          <Card className="checkout-custom-card">
                            <Skeleton active />
                          </Card>
                        </>
                      ) : totalPaxArr.length > 0 ? (
                        <>
                          <div>
                            <Form
                              layout="vertical"
                              className="passenger-form user-details "
                              validateMessages={validateMessages}
                              form={passenger_form}
                              initialValues={{ passengers: totalPaxArr }}
                            >
                              <div className="trav-details">
                                <div className="travhead">
                                  <span className="heading-trav">
                                    <UserOutlined
                                      className="site-form-item-icon"
                                      style={{ marginTop: "4px" }}
                                    />
                                    <p> Traveller Details</p>
                                  </span>
                                  <span className="end-card">
                                    <IdcardOutlined
                                      className="site-form-item-icon"
                                      style={{ marginTop: "9px" }}
                                    />
                                    <p>
                                      {" "}
                                      Name should be same as in Government ID
                                      proof
                                    </p>
                                  </span>
                                </div>

                                <Form.List name="passengers">
                                  {(fields) => {
                                    return fields.map((field, index) => (
                                      <div className="pax-info-card">
                                        <div className="pax-head-title" style={{ display: "flex", justifyContent: "space-between", marginRight: 10 }}>

                                          {totalPaxArr[index].paxType ===
                                            "ADT" ? (
                                            <Tooltip
                                              placement="right"
                                              title={"12+ years"}
                                            >
                                              {"Adult - " +
                                                totalPaxArr[index].pax}
                                            </Tooltip>
                                          ) : totalPaxArr[index].paxType ===
                                            "CHD" ? (
                                            <Tooltip
                                              placement="right"
                                              title={"2-12 yrs"}
                                            >
                                              {"Child - " +
                                                totalPaxArr[index].pax}
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              placement="right"
                                              title={"0-2 yrs"}
                                            >
                                              {"Infant - " +
                                                totalPaxArr[index].pax}
                                            </Tooltip>
                                          )}
                                          <Row >
                                            {flag && (
                                              <>
                                                <div className="pax-head-hr">
                                                  <hr />
                                                </div>

                                                <div>
                                                  <Select
                                                    placeholder="Choose"
                                                    onSelect={(val) => {
                                                      handleTravelerDetails(
                                                        val,
                                                        index
                                                      );
                                                    }}
                                                    style={{ width: "100%" }}
                                                  >
                                                    {travellersListResp.Travellers.map(
                                                      (travelerName) => (
                                                        <Option
                                                          value={
                                                            travelerName.TravellerID
                                                          }
                                                          key={
                                                            travelerName.TravellerID
                                                          }
                                                        >
                                                          {travelerName.FirstName +
                                                            " " +
                                                            travelerName.LastName}
                                                        </Option>
                                                      )
                                                    )}
                                                  </Select>
                                                </div>
                                              </>
                                            )}
                                          </Row>
                                        </div>
                                        <Card className="checkout-custom-card">
                                          <Row gutter={[16, 8]}>
                                            <Col md={4} sm={8} xs={24}>
                                              <Form.Item
                                                {...field}
                                                name={[index, "title"]}
                                                label="Title"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Specify Title",
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  placeholder="Choose"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Required",
                                                    },
                                                  ]}
                                                >
                                                  {totalPaxArr[index].paxType ===
                                                    "ADT" ? (
                                                    <>
                                                      <Select.Option value="MR">
                                                        Mr
                                                      </Select.Option>
                                                      <Select.Option value="MRS">
                                                        Mrs
                                                      </Select.Option>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Select.Option value="MSTR">
                                                        Mstr
                                                      </Select.Option>
                                                    </>
                                                  )}

                                                  <Select.Option value="MS">
                                                    Ms
                                                  </Select.Option>
                                                </Select>
                                              </Form.Item>
                                            </Col>
                                            <Col md={10} sm={8} xs={24}>
                                              <Form.Item
                                                {...field}
                                                name={[index, "firstName"]}
                                                label="First Name"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Required",
                                                  },
                                                  {
                                                    pattern: "^[a-zA-Z ]*$",
                                                    message:
                                                      "Alphabet Characters Only",
                                                  },
                                                  {
                                                    min: 2,
                                                    message:
                                                      "First Name must be minimum 2 characters.",
                                                  },
                                                  {
                                                    max: 32,
                                                    message:
                                                      "First Name must be maximum 32 characters.",
                                                  },
                                                  ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                      if (
                                                        !value ||
                                                        getFieldValue([
                                                          "passengers",
                                                          index,
                                                          "lastName",
                                                        ]) != value
                                                      ) {
                                                        if (
                                                          !abbrevations.includes(
                                                            value
                                                          )
                                                        ) {
                                                          return Promise.resolve();
                                                        } else {
                                                          return Promise.reject(
                                                            new Error(
                                                              "Abbrevations are not accepted in beginning."
                                                            )
                                                          );
                                                        }
                                                      }
                                                      return Promise.reject(
                                                        new Error(
                                                          "The First and Last Name should not be same."
                                                        )
                                                      );
                                                    },
                                                  }),
                                                ]}
                                              >
                                                <Input
                                                  placeholder="First name & Middle name (if any)"
                                                  className="inputbg"
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col md={10} sm={8} xs={24}>
                                              <Form.Item
                                                {...field}
                                                name={[index, "lastName"]}
                                                label="Last Name"
                                                style={{
                                                  fontSize: "12px",
                                                  fontStyle: "bold",
                                                }}
                                                placeholder="Enter Last Name"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Required",
                                                  },
                                                  {
                                                    min: 2,
                                                    message:
                                                      "Last Name must be minimum 2 characters.",
                                                  },
                                                  {
                                                    max: 32,
                                                    message:
                                                      "Last Name must be maximum 32 characters.",
                                                  },
                                                  {
                                                    pattern: "^[a-zA-Z ]*$",
                                                    message:
                                                      "Alphabet Characters Only",
                                                  },
                                                  ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                      if (
                                                        !value ||
                                                        getFieldValue([
                                                          "passengers",
                                                          index,
                                                          "firstName",
                                                        ]) != value
                                                      ) {
                                                        if (
                                                          !abbrevations.includes(
                                                            value
                                                          )
                                                        ) {
                                                          return Promise.resolve();
                                                        } else {
                                                          return Promise.reject(
                                                            new Error(
                                                              "Abbrevations are not accepted in beginning."
                                                            )
                                                          );
                                                        }
                                                      }
                                                      return Promise.reject(
                                                        new Error(
                                                          "The First and Last Name should not be same."
                                                        )
                                                      );
                                                    },
                                                  }),
                                                ]}
                                              >
                                                <Input
                                                  placeholder="Last name"
                                                  className="inputbg"
                                                />
                                              </Form.Item>
                                            </Col>

                                            {totalPaxArr[index].hasOwnProperty(
                                              "dob"
                                            ) && (
                                                <Col md={8} sm={8} xs={24}>
                                                  <Form.Item
                                                    label="Date of birth"
                                                    name={[index, "dob"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Required",
                                                      },
                                                      {
                                                        validator: (_, v) =>
                                                          PaxAgeValidator(
                                                            totalPaxArr[index]
                                                              .paxType,
                                                            v
                                                          ),
                                                      },
                                                    ]}
                                                  >
                                                    <DatePicker
                                                      className="inputbg"
                                                      allowClear={false}
                                                      format={dateformat}
                                                      style={{ width: "100%" }}
                                                      disabledDate={
                                                        disabledFutureDate
                                                      }
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              )}
                                            {totalPaxArr[index].hasOwnProperty(
                                              "passengerNationality"
                                            ) && (
                                                <Col md={8} sm={8} xs={24}>
                                                  <Form.Item
                                                    {...field}
                                                    name={[
                                                      index,
                                                      "passengerNationality",
                                                    ]}
                                                    label="Passenger Nationality"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Required",
                                                      },
                                                      {
                                                        pattern: "^[a-zA-Z ]*$",
                                                        message:
                                                          "Alphabet Characters Only",
                                                      },
                                                    ]}
                                                  >

                                                    <Select

                                                      showSearch
                                                      placeholder="Select Country"
                                                      filterOption={(input, option) =>
                                                        option.children
                                                          .toLowerCase()
                                                          .indexOf(input.toLowerCase()) >= 0
                                                      }
                                                    >
                                                      {CountryList.map((item) => (
                                                        <Option key={item.code} value={item.code}>
                                                          {item.name}
                                                        </Option>
                                                      ))}
                                                    </Select>
                                                  </Form.Item>
                                                </Col>
                                              )}
                                            {totalPaxArr[index].hasOwnProperty(
                                              "passportNumber"
                                            ) && (
                                                <Col md={8} sm={8} xs={24}>
                                                  <Form.Item
                                                    name={[index, "passportNumber"]}
                                                    label="Passport Number"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Required",
                                                      },
                                                    ]}
                                                  >
                                                    <Input
                                                      className="inputbg"
                                                      placeholder="Passport Number"
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              )}
                                            {totalPaxArr[index].hasOwnProperty(
                                              "passportDOI"
                                            ) && (
                                                <Col md={8} sm={8} xs={24}>
                                                  <Form.Item
                                                    name={[index, "passportDOI"]}
                                                    label="Passport DOI"
                                                    className="passport-dates"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Required",
                                                      },
                                                    ]}
                                                  >
                                                    <DatePicker
                                                      format={dateformat}
                                                      style={{ width: "100%" }}
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              )}
                                            {totalPaxArr[index].hasOwnProperty(
                                              "passportDOE"
                                            ) && (
                                                <Col md={8} sm={8} xs={24}>
                                                  <Form.Item
                                                    name={[index, "passportDOE"]}
                                                    label="Passport DOE"
                                                    className="passport-dates"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Required",
                                                      },
                                                    ]}
                                                  >
                                                    <DatePicker
                                                      format={dateformat}
                                                      style={{ width: "100%" }}
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              )}
                                            {totalPaxArr[index].hasOwnProperty(
                                              "passportIssuedCountry"
                                            ) && (
                                                <Col md={8} sm={8} xs={24}>
                                                  <Form.Item
                                                    name={[
                                                      index,
                                                      "passportIssuedCountry",
                                                    ]}
                                                    label="Passport Issue Country"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "Required",
                                                      },
                                                    ]}
                                                  >
                                                    <Select
                                                      showSearch
                                                      placeholder="Select Passport Issued Country"
                                                      filterOption={(
                                                        input,
                                                        option
                                                      ) =>
                                                        option.children
                                                          .toLowerCase()
                                                          .indexOf(
                                                            input.toLowerCase()
                                                          ) >= 0
                                                      }
                                                    >
                                                      {CountryList.map((item) => (
                                                        <Option
                                                          key={item.name}
                                                          value={item.name}
                                                        >
                                                          {item.name}
                                                        </Option>
                                                      ))}
                                                    </Select>
                                                  </Form.Item>
                                                </Col>
                                              )}
                                          </Row>
                                        </Card>
                                      </div>
                                    ));
                                  }}
                                </Form.List>
                                {showallssrdetails ?
                                  <>
                                    <div className="ssr-in"> Additional Services</div>
                                    <Card className="checkout-custom-card">

                                      <div className="user-details1">
                                        <div className="inf-ssr">

                                        </div>
                                        <div className="ssr-div">
                                          {showameal ?
                                            <div className="ssr-block" onClick={() => setmealVisible(true)}>
                                              <img src={admeal} alt="" />
                                              Add Meals
                                            </div> : null}
                                          {showbagg ?
                                            <div className="ssr-block" onClick={() => setbaggageVisible(true)}>
                                              <img src={adbag} alt="" />
                                              Add Baggage
                                            </div> : null}
                                        </div>
                                        <div className="inf-ssr">
                                          <div className="ssr-inf-1"><i class="fa fa-info-circle" aria-hidden="true"></i> Info : </div>
                                          <div className="ssr-inf-p">The Above Selected Meal are Subject to Availability</div>
                                        </div>
                                      </div>
                                    </Card>
                                  </> : null}
                              </div>

                            </Form>

                          </div>
                        </>
                      ) : null}
                    </div>
                  ) : null}

                  {showalldetails ? (
                    <div>
                      <Form
                        layout="vertical"
                        className="passenger-form"
                        form={gst_form}
                      >
                        <div className="gst-req-form">
                          <Checkbox onChange={(e) => onCheckChange(e)}>
                            <h6>Use GST For This Booking</h6>
                            <p>
                              To Claim credit of GST Charged by airlines/
                              trypmaker, please enter your Company's GST number
                            </p>
                          </Checkbox>

                          {gstReq || isGstRequired ? (
                            <div className="user-details gst-details margin-contact">
                              <p className="heading">GST Details</p>

                              <Card className="gst-custom-details flight-cards-details">
                                <Row gutter={[16, 8]}>
                                  <Col md={8} sm={8} xs={24}>
                                    <Form.Item
                                      label="Company Name"
                                      name="gstCompanyName"
                                      rules={[
                                        {
                                          required: true,
                                          message: isGstRequired,
                                        },
                                      ]}
                                    >
                                      <Input className="contacts-value-de" />
                                    </Form.Item>
                                  </Col>
                                  <Col md={8} sm={8} xs={24}>
                                    <Form.Item
                                      label="GST Number"
                                      name="gstNumber"
                                      rules={[
                                        {
                                          required: true,
                                          message: isGstRequired,
                                        },
                                        {
                                          pattern:
                                            "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",

                                          message:
                                            "Please Enter a Valid GST Number",
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="contacts-value-de"
                                        placeholder="GST Number"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col md={8} sm={8} xs={24}>
                                    <Form.Item
                                      label="Contact Number"
                                      name="gstPhoneNo"
                                      rules={[
                                        {
                                          required: true,
                                          message: isGstRequired,
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="contacts-value-de"
                                        placeholder="Contact Number"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col md={8} sm={8} xs={24}>
                                    <Form.Item
                                      label="Company Email"
                                      name="gstEmailId"
                                      rules={[
                                        {
                                          required: true,
                                          message: isGstRequired,
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="contacts-value-de"
                                        placeholder="Company Email"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col md={8} sm={8} xs={24}>
                                    <Form.Item
                                      label="Company Address"
                                      name="gstAddressLine1"
                                      rules={[
                                        {
                                          required: true,
                                          message: isGstRequired,
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="contacts-value-de"
                                        placeholder="Company Address"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Card>
                            </div>
                          ) : null}
                        </div>

                      </Form>
                    </div>) : null}


                  {showalldetails ? null :
                    <div className="check-in-Rules">
                      <div className="check-in-hdr">
                        <h6><span style={{ marginRight: "5px" }}>< LikeOutlined /></span>Good To Know</h6>
                        <p>Information you should Know</p>
                      </div>
                      <div className="check-in">
                        <p><span><i class="fa fa-hand-o-right" aria-hidden="true"></i></span>Remember to web check-in before arriving at the airport</p>
                        <p><span><i class="fa fa-hand-o-right" aria-hidden="true"></i></span>Face masks are advisable</p>
                        <p><span><i class="fa fa-hand-o-right" aria-hidden="true"></i></span>Reach Before Prior to Depaurter time, & go Tension free with Trypmaker</p>

                      </div>
                    </div>}
                </Col>
                <Col className="checkout-top-part">
                  <div className="checkout-sticky-part">
                    {isLoading ? (
                      <>
                        <div className="sticky-card-container">
                          <Card className="pax-count-card flight-cards-details">
                            <Skeleton active />
                          </Card>
                        </div>
                        <div className="grand-total-card">
                          <Card className="flight-cards-details">
                            <Skeleton active />
                          </Card>
                        </div>
                      </>
                    ) : (
                      airPriceResp && (
                        <>
                          {airPriceResp?.flightDetails?.length > 0 ? (
                            <div className="grand-total-card">
                              <FlightGrandTotalCard
                                airPriceResp={airPriceResp}
                                flightSearchObj={flightSearchObj}
                                mealdetails={mealData}
                                baggagedetails={baggData}
                                location={location}
                              />
                            </div>
                          ) : null}
                        </>
                      )
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col sm={24} md={16}>
                  {showalldetails ? (
                    <BookPayCard
                      isLoading={isLoading}
                      bookpaycardinfo={"flight-checkout"}
                      blockApiReq={blockApiReq}
                      pgDisplay={pgDetails.pgDisplay}
                      pgData={pgDetails.pgData}
                      processPayGateway={processPayGateway}
                      redirectToPreview={redirectToPreview}
                      loadingSpin={loadingSpin}
                    />
                  ) : null}


                  <Col className="checkout-top-part1">
                    <div className="checkout-sticky-part">
                      {isLoading ? (
                        <>
                          <div className="sticky-card-container">
                            <Card className="pax-count-card flight-cards-details">
                              <Skeleton active />
                            </Card>
                          </div>
                          <div className="grand-total-card">
                            <Card className="flight-cards-details">
                              <Skeleton active />
                            </Card>
                          </div>
                        </>
                      ) : (
                        airPriceResp && (
                          <>
                            {airPriceResp?.flightDetails?.length > 0 ? (
                              <div className="grand-total-card">
                                <FlightGrandTotalCard
                                  airPriceResp={airPriceResp}
                                  flightSearchObj={flightSearchObj}
                                  mealdetails={mealData}
                                  baggagedetails={baggData}
                                  location={location}
                                />
                              </div>
                            ) : null}
                          </>
                        )
                      )}
                    </div>

                  </Col>
                  {/* {isLoading ? <div style={{ marginTop: "2%" }}>
                    {!showalldetails ? (
                      <Button

                        className="btn-continue"
                        // onClick={() => getDetails()}
                      >
                        Continue Booking
                      </Button>
                    ) : null}
                  </div> :
                  <div style={{ marginTop: "2%" }}>
                    {!showalldetails ? (
                      <Button

                        className="btn-continue"
                        onClick={() => getDetails()}
                      >
                        Continue Booking
                      </Button>
                    ) : null}
                  </div>
} */}
                  <div style={{ marginTop: "2%", backgroundColor: isLoading ? "#e2f1ff" : "" }}>
                    {!showalldetails ? (
                      <Button
                        className="btn-continue"
                        onClick={!isLoading ? () => getDetails() : null}
                        style={{
                          backgroundColor: isLoading ? "#174998" : "",
                          color: "#fff",
                          cursor: isLoading ? "not-allowed" : "pointer",
                        }}
                        disabled={isLoading} // Disable the button while loading
                      >
                        {isLoading ? "Loading..." : "Continue Booking"}
                      </Button>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </div >
      <Modal
        title="Meal Prefarence"
        className="promo-modal-header modal-header-bg12"
        visible={mealVisible}

        onCancel={(e) => setmealVisible(false)}
        footer={[
          <div className="mdl-footer">
            {showbagg ?
              <div className="ssr-div">
                <div className="ssr-block" onClick={() => onChangeModal()}>
                  <img src={adbag} alt="" />
                  Add Baggage
                </div>
              </div> : null}
            <div className="btn">

              <Button
                type="primary"
                onClick={() => {
                  setmealVisible(false);

                }}
              >
                OK

              </Button>

            </div>
          </div>,
        ]}

        width={"1100px"}
      >

        <div className="ssr-body">
          {airPriceResp?.specialServiceRequest?.map((flight, id) => {
            return (
              <div className="ssr-part">

                <div className="hdr-type">
                  <div className="ssr-hdr">
                    Travellers
                  </div>
                  {getHeader(airPriceResp?.specialServiceRequest?.[id]?.MEAL)?.map((e, index) => {
                    return (
                      <>
                        <div className="seg-ssr" onClick={() => { }}>{e} <span className="fli-ssr"><img src={fliy} alt="" /></span></div>
                      </>)
                  })
                  }
                </div>

                <div className="ssr-pax-hdr">{mealPlanPaxArr?.map((pax, i) => {

                  return (
                    <>
                      <div className="ssr-pax-block">
                        <div className="ssr-pax-type">
                          {pax?.paxType == "ADT" ? "Adult " + (i + 1) : "Child " + (i + 1)}
                        </div>
                        {getHeader(airPriceResp?.specialServiceRequest?.[id]?.MEAL)?.map((e, index) => {

                          return (<><div className="seg-ssr-part" onClick={() => { setactiveMeal([id, i]); setactiveMealSegment(e) }}>
                            {activeMeal[0] == id && activeMeal[1] == i && activeMealSegment == e ? "-" : "+ "}Add Meals </div>
                          </>)
                        })
                        }
                      </div>
                      {activeMeal[0] == id && activeMeal[1] == i &&
                        <section className="carousel-section desktop_crou">
                          <div className="ssr-b">

                            <Slider {...settingsMeal}>{airPriceResp?.specialServiceRequest?.[id]?.MEAL?.map((meal, idx) => {

                              return (<>{(meal?.code != "NoMeal") &&
                                <div className="ssr-a">
                                  {activeMealSegment == meal?.cityPair ?
                                    <div className="image-container">

                                      <img className="ssr-img" src={ssrmeal} alt="" />

                                      <div className={(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode !== "NoMeal") && madd ? "rem-meal" : "overlay"}>
                                        <div className="ovr-rem-sssr" onClick={() => getmealcode(meal, i, pax?.paxType + i, "remove")}>
                                          {(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode != "NoMeal") && madd ?
                                            <span className="rem-ssr"> Remove <CloseCircleOutlined /></span> : null}
                                        </div>

                                        <span className="pr-ssr-tag">{activeCurrency} {currencyValue(meal.amount)}</span>

                                        <div className="ssr-c">
                                          <p className="desc-ssr">{meal?.airlineDesc != null ? meal?.airlineDesc : meal?.desc}</p>
                                          <div className="but-sel" onClick={() => getmealcode(meal, i, pax?.paxType + i, "add")}>{((mealData[id]?.
                                          [GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode != "NoMeal") && madd ? "Added" : "ADD")}
                                          </div>
                                        </div>
                                      </div>
                                    </div> :
                                    <div className="image-container">

                                      <img className="ssr-img" src={ssrmeal} alt="" />

                                      <div className={(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode !== "NoMeal") && madd ? "overlay" : "rem-meal"}>


                                        <span className="pr-ssr-tag">{meal?.cityPair}</span>

                                        <div className="ssr-c">
                                          {(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode !== "NoMeal") && madd &&
                                            <p className="desc-ssr">{meal?.airlineDesc != null ? meal?.airlineDesc : meal?.desc}</p>}

                                        </div>

                                      </div>

                                    </div>}
                                </div>

                              }</>)
                            })}
                            </Slider >
                          </div>
                        </section>}
                      <section className="carousel-section mobile_crou">
                        <div className="ssr-b">

                          <Slider {...MobileSlidersettings}>{airPriceResp?.specialServiceRequest?.[id]?.MEAL?.map((meal) => {
                            return (<>{meal?.code != "NoMeal" &&
                              <div className="ssr-a">
                                <div className="image-container">
                                  <img className="ssr-img" src={ssrmeal} alt="" />
                                  <div className={(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode !== "NoMeal") && madd ? "rem-meal" : "overlay"}>
                                    <div className="ovr-rem-sssr" onClick={() => getmealcode(meal, i, pax?.paxType + i, "remove")}>
                                      {(mealData[id]?.[GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode != "NoMeal") && madd ?
                                        <span className="rem-ssr"> Remove <CloseCircleOutlined /></span> : null}
                                    </div>

                                    <span className="pr-ssr-tag">{activeCurrency} {currencyValue(meal.amount)}</span>

                                    <div className="ssr-c">
                                      <p className="desc-ssr">{meal?.airlineDesc}</p>

                                      <div className="but-sel" onClick={() => getmealcode(meal, i, pax?.paxType + i, "add")}>{((mealData[id]?.
                                      [GetIndex(meal?.cityPair)]?.[i]?.mealCode === meal.code && pax?.mealplancode != "NoMeal") && madd ? "Added" : "ADD")}

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            }</>)
                          })}
                          </Slider>
                        </div>
                      </section>
                    </>
                  )
                })}
                </div>
              </div>)
          })}

        </div>
      </Modal>
      <Modal
        title="Extra Baggage"
        className="promo-modal-header modal-header-bg12"
        visible={baggageVisible}

        onCancel={(e) => setbaggageVisible(false)}
        footer={[
          <div className="mdl-footer">
            {showameal ?
              <div className="ssr-div">
                <div className="ssr-block" onClick={() => onChangeModal()}>
                  <img src={admeal} alt="" />
                  Add Meals
                </div>
              </div> : null}
            <div className="btn">

              <Button
                type="primary"
                onClick={() => {
                  setbaggageVisible(false);

                }}
              >
                OK

              </Button>

            </div>
          </div>,
        ]}

        width={"1100px"}
      >

        <div className="ssr-body">
          {airPriceResp?.specialServiceRequest?.map((flight, id) => {
            return (
              <div className="ssr-part">

                <div className="hdr-type">
                  <div className="ssr-hdr">
                    Travellers
                  </div>
                  {getHeader(airPriceResp?.specialServiceRequest?.[id]?.BAGGAGE)?.map((e, index) => {
                    return (
                      <>
                        <div className="seg-ssr" onClick={() => { }}>{e} <span className="fli-ssr"><img src={fliy} alt="" /></span></div>
                      </>)
                  })
                  }
                </div>

                <div className="ssr-pax-hdr">{baggagePlanArr?.map((pax, i) => {

                  return (
                    <>
                      <div className="ssr-pax-block">
                        <div className="ssr-pax-type">

                          {pax?.paxType === "ADT" ? "Adult " + (i + 1) : pax?.paxType === "CHD" && pax?.paxType !== "INF" ? "Child " + (i + 1) : ""}
                        </div>
                        {pax?.paxType !== "INF" && (
                          <>
                            {getHeader(airPriceResp?.specialServiceRequest?.[id]?.BAGGAGE)?.map((e, index) => {
                              return (<><div className="seg-ssr-part" onClick={() => { setactivebagg([id, i]); setactivebaggSegment(e) }}>{activebagg[0] == id && activebagg[1] == i && activebaggSegment == e ? "-" : "+ "}Add Baggage </div>
                              </>)
                            })
                            }
                          </>
                        )}
                      </div>
                      {pax?.paxType !== "INF" && activebagg[0] == id && activebagg[1] == i && <section className="carousel-section desktop_crou">
                        <div className="ssr-b">

                          <Slider {...settings}>{airPriceResp?.specialServiceRequest?.[id]?.BAGGAGE?.map((bagg, idx) => {
                            return (<>{(bagg?.code != "NoBaggage" && (activebaggSegment == bagg?.cityPair || bagg?.cityPair == "undefined-undefined")) &&
                              <div className="ssr-a">
                                <div className="image-container">
                                  <img className="ssr-img" src={ssrbag} alt="" />

                                  <div className={(baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd ? "rem-bagg" : "overlay"}>
                                    <div className="ovr-rem-sssr" onClick={() => getbaggcode(bagg, i, pax?.paxType + i, "remove")}>
                                      {(baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd ?
                                        <span className="rem-ssr"> Remove <CloseCircleOutlined /></span> : null}
                                    </div>
                                    <span className="pr-ssr-tag">{activeCurrency} {currencyValue(bagg.amount)}</span>

                                    <div className="ssr-c">
                                      <p className="desc-ssr">{bagg?.code != null ? (bagg?.weight + " Kgs") : bagg?.code}</p>
                                      <div className="but-sel" onClick={() => getbaggcode(bagg, i, pax?.paxType + i, "add")}>{((baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd ? "Added" : "ADD")}

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            }</>)
                          })}
                          </Slider>
                        </div>
                      </section>}
                      <section className="carousel-section mobile_crou">
                        <div className="ssr-b">

                          <Slider {...MobileSlidersettings}>{airPriceResp?.specialServiceRequest?.[0]?.BAGGAGE?.map((bagg) => {
                            return (<>{bagg?.code != "NoBaggage" &&
                              <div className="ssr-a">
                                <div className="image-container">
                                  <img className="ssr-img" src={ssrbag} alt="" />
                                  <div className={(baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd ? "rem-bagg" : "overlay"}>
                                    <div className="ovr-rem-sssr" onClick={() => getbaggcode(bagg, i, pax?.paxType + i, "remove")}>
                                      {(baggData[id]?.[GetIndx(bagg?.cityPair)]?.[i]?.baggCode === bagg.code && pax?.baggplancode != "NoBaggage") && badd ?
                                        <span className="rem-ssr"> Remove <CloseCircleOutlined /></span> : null}
                                    </div>
                                    <span className="pr-ssr-tag">{activeCurrency} {currencyValue(bagg.amount)}</span>

                                    <div className="ssr-c">
                                      <p className="desc-ssr">{bagg?.weight + " Kgs"}</p>
                                      <div className="but-sel" onClick={() => getbaggcode(bagg, i)}>{(bagg.code == bcode) && badd ? "Added" : "ADD"}

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            }</>)
                          })}
                          </Slider>
                        </div>
                      </section>
                    </>
                  )
                })}
                </div>
              </div>)
          })}


        </div>
      </Modal>
      {openCCavanueCheckout && <PaymentComponent pgData={pgData} />}
    </div >
  );
};

export default FlightCheckout;
